import { graphql, useStaticQuery } from "gatsby";

const useBlogPosts = () => {
  const {
    allMdx: { nodes }
  } = useStaticQuery(
    graphql`
      query BlogPosts {
        allMdx(
          sort: { order: DESC, fields: frontmatter___date }
          filter: {
            frontmatter: {
              templateKey: { eq: "blog-post" }
              published: { eq: true }
            }
          }
        ) {
          nodes {
            id
            fields {
              uri
            }
            excerpt
            frontmatter {
              image: featuredimage {
                childImageSharp {
                  fluid(maxWidth: 500) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
              }
              tag
              title
            }
          }
        }
      }
    `
  );

  return nodes.map(({ excerpt, frontmatter, fields, id }) => ({
    ...frontmatter,
    ...fields,
    excerpt,
    id
  }));
};

export default useBlogPosts;
