import React from "react";

import useBlogPosts from "../hooks/useBlogPosts";

import BlogCard from "../components/BlogCard";
import BlogMark from "../components/BlogMark";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Layout from "../components/layout";

// import styled from "@emotion/styled";
import { css } from "@emotion/core";

import { Inter1r, Inter2r, Inter4, Inter5 } from "../styles/blog";
import { blogMd, blogLg } from "../styles/breakpoints";
import { textPrimary, bgLightBlue } from "../styles/colors";

const styles = {
  bg: css`
    background-color: ${bgLightBlue};
  `,
  blogheader: css`
    max-width: 600px;
    margin: 24px auto 40px;
    padding: 0 25px;
    text-align: center;
    color: ${textPrimary};

    @media (${blogLg}) {
      margin-top: 40px;
      margin-bottom: 72px;
    }
  `,
  postlist: css`
    margin-left: auto;
    margin-right: auto;

    @media (${blogMd}) {
      display: flex;
      flex-wrap: wrap;
      padding: 0 38px;
    }

    @media (${blogLg}) {
      padding: 0 34px;
      max-width: ${485 * 2 + 32 + 50 * 2}px;
    }
  `,
  headline: css`
    ${Inter4};
    margin-bottom: 16px;

    @media (${blogLg}) {
      ${Inter5};
    }
  `,
  subhead: css`
    ${Inter1r};

    @media (${blogLg}) {
      ${Inter2r};
    }
  `,
  post: css`
    margin: 0 25px 48px;

    @media (${blogMd}) {
      width: calc(50% - 24px);
      margin-left: 12px;
      margin-right: 12px;
    }

    @media (${blogLg}) {
      width: calc(50% - 32px);
      margin-left: 16px;
      margin-right: 16px;
      margin-bottom: 72px;
    }
  `,
};

const BlogHome = ({ posts }) => (
  <Layout
    meta={{
      meta: {
        title: "TED Masterclass Blog",
        description: "You have ideas worth sharing.",
      },
    }}
  >
    <div css={styles.bg}>
      <Header />
      <BlogMark />
      <header css={styles.blogheader}>
        <h1 css={styles.headline}>TED Masterclass Blog</h1>
        <p css={styles.subhead}>You have ideas worth sharing.</p>
      </header>
      <div css={styles.postlist}>
        {posts.map(({ id, ...post }) => (
          <BlogCard key={id} {...post} css={styles.post} />
        ))}
      </div>
      <Footer />
    </div>
  </Layout>
);

BlogHome.defaultProps = {
  posts: [],
};

const BlogHomeWithData = () => <BlogHome posts={useBlogPosts()} />;

export default BlogHomeWithData;
