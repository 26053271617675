import React from "react";
import { Link } from "gatsby";

import Image from "./Image";

import { css } from "@emotion/core";

import { Inter02, Inter01, Inter1r, Inter2, Inter3b } from "../styles/blog";
import { blogMd } from "../styles/breakpoints";
import { textPrimary, textSecondary, watermelon } from "../styles/colors";

const styles = {
  header: css`
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 4px;
  `,
  img: css`
    margin-bottom: 16px;

    @media (${blogMd}) {
      margin-bottom: 24px;
    }
  `,
  title: css`
    ${Inter2};
    color: ${textPrimary};

    @media (${blogMd}) {
      ${Inter3b};
      letter-spacing: -0.5px;
    }

    a {
      color: inherit;
      text-decoration: none;

      &:hover {
        color: ${watermelon};
      }
    }
  `,
  tag: css`
    margin-bottom: 4px;
    ${Inter02};
    text-transform: uppercase;
    color: ${textSecondary};

    @media (${blogMd}) {
      ${Inter01};
    }
  `,
  text: css`
    ${Inter01};
    line-height: 26px;
    color: ${textSecondary};

    @media (${blogMd}) {
      ${Inter1r};
      letter-spacing: -0.5px;
      line-height: 30px;
    }
  `
};

const BlogCard = ({ title, tag, excerpt, image, uri, ...props }) => (
  <article {...props}>
    <header css={styles.header}>
      <h2 css={styles.title}>
        <Link to={uri}>{title}</Link>
      </h2>
      <p css={styles.tag}>{tag}</p>
      <Link to={uri}>
        <Image image={image} css={styles.img} aspectRatio={485 / 273} />
      </Link>
    </header>
    <p css={styles.text}>{excerpt}</p>
  </article>
);

export default BlogCard;
